@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0 auto;
  font-family: 'Verdana', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
  max-width: 1450px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-paragraph-max-width {
  max-width: 140px;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.webkit-box{
  display: -webkit-box;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display: none;
}
